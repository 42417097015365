<template>
  <div>
    <b-tabs>
      <b-tab :title="title">
        <div class="div-action">
          <b-row>
            <b-col sm="6">
              <div class="input-container">
                <input type="text" v-model="search" placeholder="Pesquisar..." />
              </div>
            </b-col>
            <b-col sm="6">
              <div class="text-right">
                <Button _key="removeAll" type="danger" classIcon="fa-solid fa-trash" title="Excluir todos"
                  :disabled="productSelected.length == 0" size="small" :clicked="removeAll" />
              </div>
            </b-col>
          </b-row>
        </div>
        <ScrollBar :minHeight="300" :maxHeight="600">
          <table class="table table-responsive-xs">
            <thead>
              <tr>
                <th>
                  <span class="title-header">Produto</span>
                </th>
                <th class="text-center">
                  <span class="title-header">Patrimônio</span>
                </th>
                <th class="text-center">
                  <span class="title-header">Qtd</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody v-for="item in productSelectedFiltered">
              <ItemProductSelected :item="item" :type="type" />
            </tbody>
            <tbody v-show="productSelectedFiltered.length == 0">
              <tr>
                <td colspan="12">
                  <span>Nenhum produto adicionado!</span>
                </td>
              </tr>
            </tbody>
          </table>
        </ScrollBar>
      </b-tab>
      <b-tab title="Anexo">
        <FileManager :showInModal="true" source="moviment" :genericId="genereteGuidTemp" />
      </b-tab>
    </b-tabs>
  </div>

</template>
<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import FileManager from "@nixweb/nixloc-ui/src/component/shared/file-manager/FileManager";

import ItemProductSelected from "./ItemProductSelected.vue";

import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "ProductSelected",
  components: { Molded, ScrollBar, Button, ItemProductSelected, FileManager },
  props: {
    rentId: String,
    title: String,
    type: String,
  },
  data() {
    return {
      id: this.$route.params.id,
      search: "",
      productSelectedFiltered: []
    }
  },
  computed: {
    ...mapGetters("generateMoviment", ["productSelectedByPeriod"]),
    ...mapState("user", ["userLogged"]),
    manageByPatrimony() {
      return this.userLogged.parameterStock.manageByPatrimony;
    },
    productSelected() {
      return this.productSelectedByPeriod;
    },
    genereteGuidTemp() {
      var guidTemp = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
      this.addGuidTemp(guidTemp);
      return guidTemp;
    },
    applyFilter() {
      const searchLower = this.search.toLowerCase();
      this.productSelectedFiltered = this.productSelected.filter(item =>
        item.productFullName.toLowerCase().includes(searchLower) ||
        (item.barCode && item.barCode.toLowerCase().includes(searchLower))
      );
    }
  },
  methods: {
    ...mapMutations("generateMoviment", [
      "removeProductSelected",
      "cleanProductSelected",
      "addGuidTemp"
    ]),
    ...mapMutations("generic", ["removeLoading"]),
    remove(item) {
      this.removeProductSelected(item);
    },
    removeAll() {
      this.cleanProductSelected();
      this.removeLoading(["removeAll"]);
    },
  },
  watch: {
    search: {
      handler() {
        this.applyFilter();
      },
      deep: true,
    },
    productSelected: {
      handler() {
        this.productSelectedFiltered = this.productSelected;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.td-product {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
}

.td-bar-code {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px;
}

.td-quantity {
  width: 120px;
}

.div-action {
  margin-bottom: 13px;
}

.div-title {
  margin-bottom: 10px;
}

.title {
  font-size: 15px;
}

.input-container {
  display: flex;
  justify-content: center;
  margin: 0px;
}

input {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 1px solid #E5E4E8;
  border-radius: 20px;
  outline: none;
  font-size: 13px;
  width: 300px;
}
</style>