<template>
  <div>
    <br />
    <div class="text-center">
      <div class="div-molded">
        <Molded>
          <div class="msg-success">{{ msgSuccess }}</div>
          <div class="icon-success">
            <i class="fa-light fa-circle-check"></i>
          </div>
          <div>
            <Button _key="btnPrint" type="info" title="Imprimir" classIcon="fa-solid fa-print-magnifying-glass"
              size="small" :clicked="executePrint" />
          </div>
          <br />
        </Molded>
      </div>
    </div>
    <PrintPreview v-if="print" module="MovementStock" :rentId="rentId" :movimentId="movimentId"
      :onHideModal="onHideModal" />
  </div>
</template>

<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import PrintPreview from "../../adm/document/PrintPreview.vue";

import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "SuccessMoviment",
  components: {
    Button,
    Molded,
    PrintPreview,
  },
  props: {
    msgSuccess: String,
    rentId: String,
    movimentId: String,
  },
  data() {
    return {
      print: false,
      urlUpdate: "/api/v1/adm/attachment/update-guid-temp-to-moviment-id",
    };
  },
  mounted() {
    this.updateGuidTempToMovimentId();
  },
  computed: {
    ...mapState("generateMoviment", ["guidTemp"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    ...mapActions("generic", ["putApi"]),
    ...mapMutations("generateMoviment", ["addGuidTemp"]),
    executePrint() {
      this.print = true;
      this.removeLoading(["btnPrint"]);
    },
    onHideModal() {
      this.print = false;
    },
    updateGuidTempToMovimentId() {
      let params = {
        url: this.urlUpdate,
        obj: {
          id: this.guidTemp,
          any: this.movimentId,
        },
        notNotifyToast: true
      };
      this.putApi(params).then((response) => {
        this.addGuidTemp("");
      });
    }
  },
};
</script>

<style scoped>
.div-molded {
  width: 550px;
  margin: auto;
}

.msg-success {
  font-size: 18px;
}

.icon-success {
  font-size: 55px;
  color: #38a169;
  margin-top: 10px;
  margin-bottom: 15px;
}
</style>